/*
 * Custom JS created by yoyolabs.
 * http://yoyolabs.yeystudio.com
 * 
 */
 (function($) {
    "use strict"; // Start of use strict

    // Functions
    function getAll(selector) {
        return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
    }

    // Document ready
    $(document).ready(function() {

        // Loader
        NProgress.start();
        $('#page-load__overlay').removeClass('overlay-hidden');

        // Ajax load
        $(document).ajaxStart(function(){
            NProgress.start();
        }).ajaxStop(function(){
            NProgress.done();
        });

        // Icons
        grunticon([
          '../../fonts/grunticon/icons.data.svg.css',
          '../../fonts/grunticon/icons.data.png.css',
          '../../fonts/grunticon/icons.fallback.css'
        ], grunticon.svgLoadedCallback );

        // Nav toggle
        var $toggle = $('#nav-toggle');
        var $menu = $('#top-nav');
        var root = document.documentElement;

        $toggle.click(function() {
            $(this).toggleClass('is-active');
            $menu.toggleClass('is-active');
            root.classList.toggle('menu-open');
        });

        // Sub menu toggle
        $('.menu__top-link').on('click', function() {
            $(this).toggleClass('dropdown-open');
            $(this).siblings().removeClass('dropdown-open');
        });

        // Nav affix
        var $nav_section = $('#top-nav');
        var mainbottom = 56 + $nav_section.height();

        $(window).on('scroll',function(){
            // we round here to reduce a little workload
            var stop = Math.round($(window).scrollTop());

            if (stop > mainbottom) {
                $nav_section.addClass('nav__section--active');
            } else {
                $nav_section.removeClass('nav__section--active');
            }
        });

        // Search toggle
        $('.menu__top-link--search').on('click', function() {
            $(this).toggleClass('search-box-open');
        });
        $(".menu__top-link--search__wrapper").click(function(e) {
            e.stopPropagation();
        });

        // Modals
        var $html = document.documentElement;
        var $modals = getAll('.modal');
        var $modalButtons = getAll('.modal-button');
        var $modalCloses = getAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button');

        if ($modalButtons.length > 0) {
            $modalButtons.forEach(function ($el) {
                $el.addEventListener('click', function () {
                    var target = $el.dataset.target;
                    var $target = document.getElementById(target);
                    $html.classList.add('is-clipped');
                    $target.classList.add('is-active');
                });
            });
        }

        if ($modalCloses.length > 0) {
            $modalCloses.forEach(function ($el) {
                $el.addEventListener('click', function () {
                    closeModals();
                });
            });
        }

        document.addEventListener('keydown', function (event) {
            var e = event || window.event;
            if (e.keyCode === 27) {
                closeModals();
                closeDropdowns();
            }
        });

        function closeModals() {
            $html.classList.remove('is-clipped');
            $modals.forEach(function ($el) {
                $el.classList.remove('is-active');
            });
        }

        // Quick links image sizer
        $("img.scale").imageScale({
            rescaleOnResize: true
        });

    }); // End Dcoument Ready

    // Window Load
    $(window).load(function(){

        // Loader
        NProgress.done();
        $('#page-load__overlay').addClass('overlay-hidden');

    }); // End Window Load

    // Window Resize
    $(window).resize(function(){

    }); // End Window Resize

    // Window Unload
    $(window).on('beforeunload', function () {

        // Loader
        NProgress.start();
        $('#page-load__overlay').removeClass('overlay-hidden');

    }); // End Window Unload

 })(jQuery); // End of use strict
